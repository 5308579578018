import {SkipNavContent} from '@reach/skip-nav'
import React from 'react'
import type {ReactNode} from 'react'
import slugify from 'slugify'
import styled, {css} from 'styled-components'

import {Text, tokens} from '@pleo-io/telescope'

import {Tabs} from '@/components/tabs/tabs'
import {
    maxWidthContent,
    maxWidthTablet,
    minWidthDesktop,
    paddingContentLarge,
    paddingContentSmall
} from '@/tokens'

import DraftBanner from './draft-banner'
import FeedbackFooter from './feedback-footer'
import {minWidthTOC} from './global-styles'
import LabComponent from './lab-component'
import {Status} from './status'
import type {Data, PageContext} from './types'

import {Intro, ToC} from '../docs'
import {heightHeaderMobile} from '../navigation/header-mobile'

const gridStyles = css`
    display: grid;
    grid-template-areas: 'content' 'toc';
    grid-template-columns: minmax(620px, auto) minmax(260px, 300px);
`

const Wrapper = styled.main`
    position: relative;
    min-height: 100vh;
    background-color: ${tokens.colorBackgroundStaticPage};

    @media (max-width: ${maxWidthTablet}) {
        padding-top: ${heightHeaderMobile};
    }
`

const HeaderWrapper = styled.div`
    border-bottom: ${tokens.borderStatic};

    @media (min-width: ${minWidthTOC}) {
        ${gridStyles};
    }
`

const HeaderMain = styled.div`
    position: relative;
    width: 100%;
    max-width: ${maxWidthContent};
    margin: 0 auto;
    padding-top: ${tokens.spacing28};
    padding-right: ${paddingContentSmall};
    padding-left: ${paddingContentSmall};

    @media (min-width: ${minWidthDesktop}) {
        padding-top: ${tokens.spacing40};
        padding-right: ${paddingContentLarge};
        padding-left: ${paddingContentLarge};
    }
`

const HeaderTitle = styled.div`
    margin-bottom: ${tokens.spacing36};
`

const Title = styled(Text).attrs({variant: '4xlarge-accent', as: 'h1', weight: 'bold'})`
    display: flex;
    align-items: center;

    > * {
        margin-left: ${tokens.spacing12};
    }
`

const TabsSubtitle = styled(Text)`
    min-height: 18px;
`

const OverviewPage = styled.div`
    max-width: ${maxWidthContent};
    margin: 0 auto;
    padding: ${tokens.spacing28};

    @media (min-width: ${minWidthDesktop}) {
        padding: ${tokens.spacing40} ${tokens.spacing56};
    }

    @media (min-width: ${minWidthTOC}) {
        max-width: 1050px;
    }
`

const ContentWrapper = styled.div<{layoutMode?: 'full'}>`
    @media (min-width: ${minWidthTOC}) {
        ${gridStyles};
    }
`

const Content = styled.div`
    width: 100%;
    max-width: ${maxWidthContent};
    min-height: 100%;
    margin: 0 auto;
    padding: ${paddingContentSmall};

    > img {
        margin: ${tokens.spacing24} 0;
        border-radius: ${tokens.arc12};
    }

    @media (min-width: ${minWidthDesktop}) {
        padding-top: ${tokens.spacing40};
        padding-right: ${paddingContentLarge};
        padding-bottom: ${tokens.spacing40};
        padding-left: ${paddingContentLarge};
    }
`

const TableOfContents = styled.div`
    position: relative;
    display: none;
    border-left: ${tokens.borderStatic};

    @media (min-width: ${minWidthTOC}) {
        display: block;
    }
`

const getSectionName = (path: string) => {
    if (path.includes('/foundations/')) {
        return 'Foundations'
    } else if (path.includes('/components/')) {
        return 'Components'
    } else if (path.includes('/tokens/')) {
        return 'Design Tokens'
    } else if (path.includes('/contributing/')) {
        return 'Contributing'
    } else if (path.includes('/icons/')) {
        return 'Icons'
    } else if (path.includes('/patterns/')) {
        return 'Patterns'
    } else if (path.includes('/illustrations/')) {
        return 'Illustrations'
    }

    return null
}

type Props = {
    path: string
    layoutMode: string
    status: string
    title: string
    intro: string
    tabs: string[]
    slug: string
    data: Data
    pageContext: PageContext
    children: ReactNode
    githubUrl?: string
    storybookUrl?: string
    figmaUrl?: string
    is404Page?: boolean
    editPageUrl?: string
}

const Main = ({
    path,
    layoutMode,
    status,
    title,
    intro,
    tabs,
    slug,
    data,
    pageContext,
    children,
    githubUrl,
    storybookUrl,
    figmaUrl,
    editPageUrl,
    is404Page
}: Props) => {
    const getCurrentTab = (): string => {
        if (!tabs || tabs.length === 0) {
            return ''
        }
        return slug.split('/').filter(Boolean).slice(-1)[0] || slugify(tabs[0], {lower: true})
    }

    if (is404Page) {
        return <>{children}</>
    } else if (layoutMode === 'landing') {
        return <Wrapper>{children}</Wrapper>
    }

    return (
        <Wrapper>
            <HeaderWrapper>
                <HeaderMain>
                    <HeaderTitle>
                        <TabsSubtitle variant="small-subtle" as="div">
                            {getSectionName(path)}
                        </TabsSubtitle>
                        <Title variant="4xlarge-accent" as="h1" weight="bold">
                            {title}
                            <Status status={status} />
                        </Title>
                    </HeaderTitle>
                    <Tabs tabs={tabs} currentTab={getCurrentTab()} path={path} />
                </HeaderMain>
            </HeaderWrapper>
            <SkipNavContent />
            {layoutMode === 'full' ? (
                <OverviewPage>
                    {intro && <Intro>{intro}</Intro>}
                    {children}
                </OverviewPage>
            ) : (
                <ContentWrapper>
                    <Content>
                        {intro && <Intro>{intro}</Intro>}
                        {status === 'lab' && (
                            <LabComponent
                                title={title}
                                githubUrl={githubUrl}
                                storybookUrl={storybookUrl}
                                figmaUrl={figmaUrl}
                            />
                        )}
                        {children}
                        {path !== '/changelog' && (
                            <FeedbackFooter path={path} editPageUrl={editPageUrl} />
                        )}
                    </Content>
                    {layoutMode !== 'full' && (
                        <TableOfContents>
                            <ToC
                                headings={data?.mdx?.headings}
                                minDepth={pageContext?.tocMinDepth}
                                maxDepth={pageContext?.tocMaxDepth}
                            />
                        </TableOfContents>
                    )}
                </ContentWrapper>
            )}
            {status === 'draft' && <DraftBanner />}
        </Wrapper>
    )
}

export default Main
